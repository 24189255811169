import { Component, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, NavigationEnd, Event } from "@angular/router";
import { Global } from './env';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})

export class AppComponent {
navigationByBack: boolean = false;

constructor(private global: Global, private location: Location, private router: Router, private cdr: ChangeDetectorRef) {
	global.plt.backButton.subscribeWithPriority(10, () => {
		if (window.location.href.endsWith("dongle"))
			this.global.backButtonAlert();
		else
			this.location.back();
	});

	router.events.subscribe((event: Event) => {
		if (event instanceof NavigationStart)
			this.navigationByBack = this.global.currentUrl == this.router.url.substr(1);
		if (event instanceof NavigationEnd && this.navigationByBack) {
			const st_ = this.router.url.substr(1).split("?");
			this.global.currentUrl = st_[0];
		}
	});

	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.language((err: any, v: string) => {
			this.changeLanguageAndRefresh(v);
		});
	}
}

async changeLanguageAndRefresh(v: string) {
	await this.global.changeLanguage(v);
	this.cdr.detectChanges();
	setTimeout(() => {this.cdr.detectChanges();}, 500);
}

}

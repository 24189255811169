import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Plugins } from '@capacitor/core';
import { Client } from '@capacitor-goodip/client';
import { TranslateService } from '@ngx-translate/core';
import { Map, tileLayer, marker, icon, featureGroup } from 'leaflet';
import { App } from '@capacitor/app';
import { Subject } from 'rxjs';
import { generateKeys } from './add//util';
import { VERSION } from '../../../client/version';
import * as bcrypt from 'bcryptjs';

export interface DataClient {
	ID:string;
	s:string;
	n:string;
	v:number;
	k:string;
	forceServerDNS?:boolean;
	forceUDP?:boolean;
	userID?:string;
}

export interface DataServerKey {
	subID:string;
	name:string;
	permission:number;
	expiration:number;
	key:string;
}

export interface DataServer {
	client:DataClient;
	server:{
		ID:string;
		keys:DataServerKey[];
		IP:boolean;
		file:boolean;
		desktop:boolean;
		desktopMouse:boolean;
		pwd:boolean;
		pwdText:string;
	}
}

export interface User {
	userID:string;
	token:string;
}

export interface UserFull {
	identified:boolean;
	email:string;
	phone:string;
	firstName:string;
	lastName:string;
	emailValidated:number;
	phoneValidated:number;
	identityValidated:number;
	communityStatus:number;
	dongles;
}

export interface Settings {
	useGoogleMaps: boolean;
	forceShowWebMenu: boolean;
	proxyOriginal: object;
	language: string;
	currentDongle: string;
	user: User;
	dontShowAgain: string[];
}

export interface ipInfo {
	ip: string;
	loc: {
		latlong: string;
		org: string;
		country: string;
		countryName: string;
		region: string;
		city: string;
	}
	alive?: number;
	id?: string;
	prot?: string;
}

export interface retStruct {
	error:number;
	reason:string;
}

@Injectable({
  providedIn: 'root'
})

export class Global {
VERSION: string = VERSION;
MASTERURL: string = "https://master.goodip.network";
IPINFO = {ip:"", loc:{latlong:"", org: "", country: "", countryName: "", region: "", city: ""}};
settings: any = null;
userFull: UserFull = {} as UserFull;
useKeyring: boolean = true;

currentUrl: string;
currentDongle: string;
connectStatus: number = 0;
serverStatus: number = 0;
serverNbClient: number = 0;
clientSharingIP: boolean = false;
clientSharingFile: boolean = false;
clientSharingDesktop:boolean = false;
clientSharingDesktopMouse:boolean = false;
clientSharingPwd:boolean = false;
oldIP: ipInfo;
connectedDongle: string;
datas: any = null;
dataServer: any = {};
isDev: boolean = false;
private connectedStart: number = 0;
private connectedInterval = null;
refreshTime:Subject<any> = new Subject();
refreshUI:Subject<any> = new Subject();

constructor(public plt: Platform, private navCtrl: NavController, private router: Router, private menu: MenuController, private alertCtrl: AlertController, private translate: TranslateService, private httpClient: HttpClient) {
	console.log("Version: " + this.VERSION);
	console.log("Platform: " + plt.platforms());
	this.settingsLoad();
	this.userFull.identified = false;
	this.datasLoad(true);
	//this.dataServerLoad();

	this.oldIP = this.IPINFO;
	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.invoke('isDev').then((r) => {
			this.isDev = r;
		});
	}

	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.log((err: any, v: string) => {
			console.log(v);
		});
		(<any>window).electron.ipc.open_page((err: any, v: string) => {
			this.openPage(v, true);
		});
		(<any>window).electron.ipc.connect_disconnect((err: any) => {
			console.log("connect_disconnect: Enter");
		});
	}
	this.appCommunicationInit();
}

lengthStr(l) {
	if (l <= 0)
		return "";
	else {
		let ret = "";
		if (l / 3600 >= 1)
			ret += "" + Math.floor(l / 3600) + "h ";
		l -= Math.floor(l / 3600) * 3600;
		if (l / 60 >= 1)
			ret += "" + Math.floor(l / 60) + "m ";
		l -= Math.floor(l / 60) * 60;
		ret += "" + Math.floor(l) + "s";
		return ret;
	}
}

connectedLength() {
	const now = new Date().getTime() / 1000;
	return now - this.connectedStart;
}

msgConnectionHandler(st) {
	console.log("msgConnectionHandler: " + st);
	if (st.substring(0, 2) == "ok") {
		this.connectStatus = 1;
		if (this.connectedInterval == null) {
			this.connectedStart = new Date().getTime() / 1000;
			this.connectedInterval = setInterval(() => { this.refreshTime.next(this.lengthStr(this.connectedLength())); }, 1000);
		}
		const codo = st.substring(3);
		if (typeof(this.datas[codo]) == "object")
			this.connectedDongle = codo;
		else
			console.log("msgConnectionHandler: Unrecognized connectedDongle");
	} else if (st.substring(0, 4) == "exit") {
		this.connectStatus = 0;
		this.refreshUI.next(true);
		if (this.connectedInterval != null)
			clearInterval(this.connectedInterval);
		this.connectedInterval = null;
	} else if (st.substring(0, 6) == "failed") {
		this.connectStatus = 0;
		console.log("msgConnectionHandler: Failed connection " + st.substring(7));
	}
}

datasLength() {
	if (this.datas == null)
		return 0;
	return Object.keys(this.datas).length;
}

async datasLoad(del: boolean) {
	console.log("datasLoad: Enter");
	if (this.isPlatform("android") || this.isPlatform("ios")) {
		const { value } = await Preferences.get({key: "datas"});
		if (value != null)
			this.datas = JSON.parse(value);
		else
			this.datas = {};
		if (del)
			this.datasClean();
		if (this.datasLength() > 0)
			this.currentDongle = Object.keys(this.datas)[this.datasLength() - 1];
	} else if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.invoke("load", "dongles").then((d) => {
			this.datas = typeof(d) == "object" ? d : {};
			if (del)
				this.datasClean();
			if (this.datasLength() > 0)
				this.currentDongle = Object.keys(this.datas)[this.datasLength() - 1];
			(<any>window).electron.ipc.invoke("menuData", d, this.currentDongle, this.dataServer.client?.n);
		});
	} else {
		if (this.datasLength() == 0)
			this.datas = {};
		if (del)
			this.datasClean();
		if (this.datasLength() > 0)
			this.currentDongle = Object.keys(this.datas)[this.datasLength() - 1];
	}
}

async settingsLoad() {
	console.log("settingsLoad: Enter");
	if (this.isPlatform("android") || this.isPlatform("ios")) {
		const { value } = await Preferences.get({key: "settings"});
		if (value != null)
			this.settings = JSON.parse(value);
	} else if (typeof (<any>window).electron != "undefined") {
		await (<any>window).electron.ipc.invoke("keyringEnable", this.useKeyring);
		const value = await (<any>window).electron.ipc.invoke("load", "settings");
		if (value != null)
			this.settings = value as Settings;
	}
	this.translate.setDefaultLang("en");
	if (this.settings == null || this.settings.language === undefined) {
		this.settings = {} as Settings;
		console.log("Default browser language: " + this.translate.getBrowserLang());
		this.changeLanguage(this.translate.getBrowserLang());
	} else {
		await this.translate.use(this.settings.language);
		if (typeof (<any>window).electron != "undefined")
			(<any>window).electron.ipc.invoke("menuLanguage", this.settings.language);
	}

	if (this.settings.dontShowAgain === undefined)
		this.settings.dontShowAgain = Object();

	if (this.settings.user === undefined) {
		this.settings.user = {} as User;
		this.settings.user.userID = "0";
		this.settings.user.token = "";
	} else if (this.settings.user.userID != "0" && this.settings.user.token != "")
		try {
			await this.userInit();
		} catch(e) {}
	this.settings.useGoogleMaps = false;
	this.settings.forceShowWebMenu = false;
	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.invoke("proxyget").then(async (r) => {
			this.settings.proxyOriginal = r;
			this.settingsSave();
			if (r["onoff"] == 1) {
				await this.sleepms(250);
				const answ = await this.presentQuestion(this.mytranslateP("dongle", "Recover"), this.mytranslateP("dongle", "It seems that your device is routing traffic to a dongle though no dongle is connected. This is potentially a problem as you won't be able to acess the Internet."), this.mytranslateP("dongle", "Do you want to recover this situation and disable the proxy settings?"));
				if (answ) {
					(<any>window).electron.ipc.invoke("proxyrecover").then((r) => {
						this.presentAlert(this.mytranslateP("dongle", "Recover"), this.mytranslateP("dongle", "The operation was successful."), this.mytranslateP("dongle", "Please check your Internet connection."));
					});
				}
			}
		});
	}
}

async settingsSave() {
	console.log("settingsSave: Enter");
	this.settings.currentDongle = this.currentDongle;
	let st = JSON.stringify(this.settings);
	if (this.isPlatform("android") || this.isPlatform("ios")) {
		await Preferences.set({key: "settings", value: st});
	} else if (this.isPlatform("electron"))
		(<any>window).electron.ipc.invoke("save", "settings", st);
}

async datasSave() {
	console.log("datasSave: Enter");
	let st = JSON.stringify(this.datas);
	if (this.isPlatform("android") || this.isPlatform("ios")) {
		await Preferences.set({key: "datas", value: st});
		this.datasLoad(false);
	} else if (this.isPlatform("electron")) {
		(<any>window).electron.ipc.invoke("save", "dongles", st).then(() => {
			this.datasLoad(false);
		});
	}
}

async datasClean() {
	for (const prop in this.datas)
		if (this.datas[prop].s != "0")
			delete this.datas[prop];
}

async datasAdd(val: DataClient) {
	console.log("datasAdd: " + val.ID + "_" + val.s);
	this.datas[val.ID + "_" + val.s] = val;
	await this.datasSave();
}

async datasRemove(id: string) {
	console.log("datasRemove: " + id);
	delete this.datas[id];
	await this.datasSave();
}

async userInit() {
	const response = await this.httpClient.post(this.MASTERURL + "/user/login.json", "info&userID=" + encodeURIComponent(this.settings.user.userID) + "&token=" + encodeURIComponent(this.settings.user.token), {headers:{"content-type": "application/x-www-form-urlencoded"}}).toPromise();
	const ret = response as retStruct;
	this.userFull = response["userFull"] as UserFull ?? ({} as UserFull);
	this.userFull.identified = ret.error == 0;
}

async generate() {
	let id = "600d2";
	var characters ="0123456789abcdef";
	for (var i = 5; i < 12; i++)
        id += characters.charAt(Math.floor(Math.random() * 16));
	const keys = await generateKeys();
	const serverSide:DataServer = { client:{ ID:id, s:"0", n:"My Node", v:1, k:keys.privateKey }, server:{ ID:id, keys:[{ subID:"0", name:"", permission:0, expiration:0, key:keys.publicKey }], IP:true, file:false, desktop:false, desktopMouse:false, pwd:false, pwdText:"" } };
	console.log("Generation of keys");
	return serverSide;
}

async dataServerLoad() {
	if (!this.isPlatform("android") && !this.isPlatform("ios")) {
		if (typeof (<any>window).electron != "undefined") {
			console.log("dataServerLoad: Enter");
			(<any>window).electron.ipc.invoke("load", "server").then(async (d) => {
				if (typeof(d) == "object" && Object.keys(d).length !== 0)
					this.dataServer = d;
				else {
					this.dataServer = await this.generate();
					await this.dataServerSave();
				}
			});
		} else
			this.dataServer = await this.generate();
	}
}

async dataServerSave() {
	if (typeof (<any>window).electron != "undefined") {
		console.log("dataServerSave: Enter");
		let st = JSON.stringify(this.dataServer);
		(<any>window).electron.ipc.invoke("save", "server", st);
	}
}

openPage(st:string, root:boolean) {
	var st_ = st.split("?");
	if (root)
		this.navCtrl.setDirection('root');
	if (st_[0].startsWith("account") && !this.userFull.identified)
		st_[0] = "account";
	this.currentUrl = st_[0];
	if (st_[0] == "dongle") {
		this.currentDongle = st_[1];
		if (this.datas[this.currentDongle] == null)
			this.currentDongle = Object.keys(this.datas)[this.datasLength() - 1];
		this.router.navigate(["/dongle"], {queryParams: {
			special: this.currentDongle
		}});
	} else
		this.router.navigate(["/" + st_[0]]);
	this.menu.close();
	if (typeof (<any>window).electron != "undefined")
		(<any>window).electron.ipc.invoke("menuData", this.datas, st_[0] == "dongle" ? this.currentDongle : "");
}

async presentAlert(hd, st, msg, key:string = "") {
	let checked = false;
	if (this.settings.dontShowAgain[key] !== undefined)
		return;
	const alert = await this.alertCtrl.create({
		cssClass: 'basic-alert',
		header: hd,
		subHeader: st,
		message: msg,
		buttons: [{ text:'OK', handler: data => { if (data !== undefined && data.length > 0 && data[0]) checked = true; } }],
		inputs: key != "" ? [{ label:"Don't show again", type:"checkbox", checked:false, value:true }] : []
	});
	await alert.present();
	await alert.onDidDismiss();
	if (checked) {
		this.settings.dontShowAgain[key] = true;
		this.settingsSave();
	}
}

async presentField(hd, st, msg, field) {
	let ff = "";
	const question = await this.alertCtrl.create({
		cssClass: 'basic-alert',
		header: hd,
		subHeader: st,
		message: msg,
		inputs: [{
			name: "field",
			placeholder: field,
		}],
		buttons: [{
			text: 'OK',
			handler: (data) => {ff = data.field;}
		}, {
			text: 'Cancel',
			cssClass: 'secondary',
			handler: () => {question.dismiss();}
		}]
	});
	await question.present();
	await question.onDidDismiss();
	return ff;
}

async presentQuestion(hd, st, msg, key:string = "") {
	if (this.settings.dontShowAgain[key] !== undefined)
		return false;
	let checked = false;
	let yesClicked = false;
	const question = await this.alertCtrl.create({
		cssClass: 'basic-alert',
		header: hd,
		subHeader: st,
		message: msg,
		buttons: [{
			text: 'Yes', handler: (data) => { yesClicked = true; if (data !== undefined && data.length > 0 && data[0]) checked = true; }
		}, {
			text: 'No', cssClass: 'secondary', handler: (data) => { yesClicked = false; if (data !== undefined && data.length > 0 && data[0]) checked = true; }
		}],
		inputs: key != "" ? [{ label:"Don't show again", type:"checkbox", checked:false, value:true }] : []
	});
	await question.present();
	await question.onDidDismiss();
	if (checked) {
		this.settings.dontShowAgain[key] = true;
		this.settingsSave();
	}
	return yesClicked;
}

async connect(data: DataClient, forceServerDNS: boolean, forceUDP: boolean) {
	data.forceServerDNS = forceServerDNS;
	data.forceUDP = forceUDP;
	data.userID = this.settings.user.userID;
	const v = await Client.connect(data);
	if (v.ret != 0)
		this.presentAlert("ERROR:", "No executable found", "Please report this error via the support website");
	return v.ret;
}

async disconnect() {
	await Client.disconnect();
}

openBrowser(url: string) {
	if (typeof (<any>window).electron != "undefined")
		(<any>window).electron.ipc.invoke("openBrowser", url);
	else
		window.open(url, "_blank");
}

async sleepms(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

async changeLanguage(st) {
	if (st != this.settings.language) {
		this.settings.language = st;
		this.settingsSave();
		await this.translate.use(this.settings.language);
		if (typeof (<any>window).electron != "undefined")
			(<any>window).electron.ipc.invoke("menuLanguage", this.settings.language);
	}
}

mytranslateP(page, st) {
	const inp = page + "." + st;
	const ret = this.translate.instant(page + "." + st);
	return ret == "" ? (this.isDev && this.settings.language != "en" ? ("##" + st + "##") : st) : ret == inp ? (this.isDev ? ("##" + st + "##") : st) : ret;
}

mytranslate(st) {
	return this.mytranslateP(this.currentUrl, st);
}

async backButtonAlert() {
	const alert = await this.alertCtrl.create({
		message: 'Do you want to leave the application?',
		buttons: [{
			text: "cancel",
			role: 'cancel'
		},{
			text: "Close App",
			handler: () => {
				App.exitApp();
			}
		}]
	});
	await alert.present();
}

updateOldIP() {
	if (this.connectStatus == 0)
		this.httpClient.get(this.MASTERURL + "/status.json").subscribe((response) => {
			this.oldIP = response as ipInfo;
			if (this.isPlatform("web")) {
				navigator.geolocation.getCurrentPosition((p) => {
					this.oldIP.loc.latlong = "" + p.coords.latitude + "," + p.coords.longitude;
				});
			}
		}, (error) => {
			console.log(error.message);
		});
}

async askPermission(perm) {
	if (typeof (<any>window).electron != "undefined")
		await (<any>window).electron.ipc.invoke("askPermission", perm);
}

demo() {
	const dgl: DataClient = { ID:"ffffffffffff", s:"0", n:this.mytranslateP("dongle", "Demo"), v:1, k:"" };
	this.datasAdd(dgl);
	this.openPage("dongle?" + dgl.ID, false);
}

async hashPassword(plaintextPassword) {
	return await bcrypt.hash(plaintextPassword, 10);
}
 
async comparePassword(plaintextPassword, hash) {
	return await bcrypt.compare(plaintextPassword, hash.replace(/\n/g,""));
}

async sharingClientDesktopRequest(on, cb, pwd) {
	console.log("Calling sharingDesktopRequest " + on);
	if (on)
		this.handlers["" + this.APP_CLIENT_PORT].desktop.cb = cb;
	Client.appCommunicationWrite({ port:this.APP_CLIENT_PORT, idx:this.handlers["" + this.APP_CLIENT_PORT].com.idx, data:on ? ("sharingDesktopStart" + await this.hashPassword(pwd ?? "")) : "sharingDesktopStop" });
}

sharingDesktopMediaRecorder = null;

async sharingDesktopStart() {
	console.log("Calling sharingDesktopStart");
	const mediaDevices = navigator.mediaDevices as any;
	let videoLive;
	if (typeof (<any>window).electron != "undefined") {
		const sources = await (<any>window).electron.ipc.invoke("getDesktopSources");
		videoLive = await mediaDevices.getUserMedia({
			audio: false,
			video: {
				mandatory: {
					chromeMediaSource: "desktop",
					chromeMediaSourceId: sources[0].id
				}
			}
		});
	} else
		videoLive = await mediaDevices.getDisplayMedia({
			audio: false,
			video: { cursor:"always" }
		});

	this.sharingDesktopMediaRecorder = new MediaRecorder(videoLive, {
		mimeType: 'video/webm;codecs=vp8'
	});
	this.sharingDesktopMediaRecorder.ondataavailable = async (e) => {
		//console.log("Size " + Math.floor(e.data.size / 1024) + "kiB/s");
		if (this.handlers["" + this.APP_SERVER_PORT].desktop.idx != -1)
			Client.appCommunicationWrite({ port:this.APP_SERVER_PORT, idx:this.handlers["" + this.APP_SERVER_PORT].desktop.idx, data:await e.data.arrayBuffer() });
	}
	this.sharingDesktopStart2();
}

sharingDesktopStart2() {
	console.log("Calling sharingDesktopStart2 ready:" + (this.handlers["" + this.APP_SERVER_PORT].desktop.idx != -1));
	if (this.handlers["" + this.APP_SERVER_PORT].desktop.idx != -1)
		setTimeout(() => { this.sharingDesktopMediaRecorder.start(100); }, 1000);
	else
		setTimeout(() => { this.sharingDesktopStart2(); }, 1000);
}

async sharingDesktopStop() {
	console.log("Calling sharingDesktopStop");
	if (this.sharingDesktopMediaRecorder != null)
		await this.sharingDesktopMediaRecorder.stop();
	this.sharingDesktopMediaRecorder = null;
	this.handlers["" + this.APP_SERVER_PORT].desktop.idx = -1;
}

APP_CLIENT_PORT = 4351;
APP_SERVER_PORT = 4352;
handlers = {};

updateStatusFromServer() {
	const st = "STATUS:" + (this.dataServer.server.IP ? 1 : 0) + ":" + (this.dataServer.server.file ? 1 : 0) + ":" + (this.dataServer.server.desktop ? 1 : 0) + ":" + (this.dataServer.server.desktopMouse ? 1 : 0) + ":" + (this.dataServer.server.pwd ? 1 : 0);
	if (this.handlers["" + this.APP_SERVER_PORT].com.idx != -1)
		Client.appCommunicationWrite({ port:this.APP_SERVER_PORT, idx:this.handlers["" + this.APP_SERVER_PORT].com.idx, data:st });
	if (this.serverStatus == 1 && this.sharingDesktopMediaRecorder != null)
		this.sharingDesktopStop();
}

async msgAppCommunicationHandler(v) {
	if (v.base64) {
		const res = await fetch("data:application/octet-binary;base64," + v.data);
		const buffer = await res.arrayBuffer();
		v.data = new Uint8Array(buffer);
	}
	Object.keys(this.handlers["" + v.port]).forEach(child => {
		if (this.handlers["" + v.port][child].idx == v.idx) {
			this.handlers["" + v.port][child].cb(v.data);
			return;
		}
	});
	if (v.data.length == 8) {
		const st = new TextDecoder().decode(v.data);
		console.log("msgAppCommunicationHandler: Reception " + st);
		if (st == "CHANNEL1") {
			console.log("#" + st + "#: Adding channel COM");
			this.handlers["" + v.port].com.idx = v.idx;
			if (v.port == this.APP_SERVER_PORT) {
				this.updateStatusFromServer();
				this.serverNbClient++;
				this.refreshUI.next(true);
			}
		} else if (st == "CHANNEL2") {
			console.log("#" + st + "#: Adding channel DESKTOP");
			this.handlers["" + v.port].desktop.idx = v.idx;
		} else if (st == "CHANNEL3") {
			console.log("#" + st + "#: Adding channel FILE");
			this.handlers["" + v.port].file.idx = v.idx;
		}
	}
}

appCommunicationInit() {
	Client.appCommunicationCreate({port:this.APP_CLIENT_PORT});
	this.handlers["" + this.APP_CLIENT_PORT] = {};
	this.handlers["" + this.APP_CLIENT_PORT].com = { idx:-1, cb:(data) => {
		const st = new TextDecoder().decode(data);
		console.log("Client receive COM: " + st);
		if (st.startsWith("STATUS:")) {
			const st_ = st.split(":");
			this.clientSharingIP = st_[1] == "1";
			this.clientSharingFile = st_[2] == "1";
			this.clientSharingDesktop = st_[3] == "1";
			this.clientSharingDesktopMouse = st_[4] == "1";
			this.clientSharingPwd = st_[5] == "1";
			this.refreshUI.next(true);
		}
		else if (st == "DESKTOPFAILEDPWD")
			this.presentAlert(this.mytranslateP("import", "Error"), this.mytranslateP("dongle", "The password is not correct."), this.mytranslateP("import", "Please try again!"));
	} };
	this.handlers["" + this.APP_CLIENT_PORT].desktop = { idx:-1, cb:(data) => {} };
	this.handlers["" + this.APP_CLIENT_PORT].file = { idx:-1, cb:(data) => {} };

	if (!this.isPlatform("android") && !this.isPlatform("ios")) {
		if (this.isPlatform('electron'))
			Client.appCommunicationCreate({port:this.APP_SERVER_PORT});
		this.handlers["" + this.APP_SERVER_PORT] = {};
		this.handlers["" + this.APP_SERVER_PORT].com = { idx:-1, cb:async (data) => {
			const st = new TextDecoder().decode(data);
			console.log("Server receive COM: " + st);
			if (st.startsWith("sharingDesktopStart")) {
				if (!this.dataServer.server.desktop)
					console.log("Wrong !");
				else if (!this.dataServer.server.pwd || await this.comparePassword(this.dataServer.server.pwdTxt, st.substr(19)))
					this.sharingDesktopStart();
				else {
					console.log("Wrong desktop sharing password!");
					Client.appCommunicationWrite({ port:this.APP_SERVER_PORT, idx:this.handlers["" + this.APP_SERVER_PORT].com.idx, data:"DESKTOPFAILEDPWD" });
				}
			} else if (st.startsWith("sharingDesktopStop"))
				this.sharingDesktopStop();
			else if (st == "END") {
				this.serverNbClient--;
				this.refreshUI.next(true);
			}
		} };
		this.handlers["" + this.APP_SERVER_PORT].desktop = { idx:-1, cb:(data) => {} };
		this.handlers["" + this.APP_SERVER_PORT].file = { idx:-1, cb:(data) => {} };
	}
}

async showMap(modalMaps, oldIP, dongleIP, wh) {
	const lato = oldIP.loc.latlong.split(",")[0];
	const longo = oldIP.loc.latlong.split(",")[1];
	const latd = dongleIP.loc.latlong.split(",")[0];
	const longd = dongleIP.loc.latlong.split(",")[1];
	await modalMaps.present();
	if (lato == latd && longo == longd) {
		//longo += 0.1;
		//longd += 0.1;
	}
	if (this.settings.useGoogleMaps) {
		document.getElementById("iframeMaps" + wh).style.display = "inline";
		document.getElementById("iframeMaps" + wh)["src"] = "https://maps.google.com/maps?q=" + latd + "," + longd + "&z=12&iwloc=B&output=embed";
	} else {
		document.getElementById("iframeMaps" + wh).style.display = "none";
		const leafletMap = new Map("divMaps" + wh);
		tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(leafletMap);
		const markers = [
			marker([lato, longo], {icon: icon({
				iconUrl: '/assets/pinpoint.png',
				iconSize: [32, 32],
				popupAnchor: [0, -20]
			})}).bindPopup("<b>" + this.mytranslate("Origin") + "<br>" + oldIP.loc.org + "<br>(" + oldIP.ip + ")</b>", {autoClose: false}),
			marker([latd, longd], {icon: icon({
				iconUrl: '/assets/pinpoint.png',
				iconSize: [32, 32],
				popupAnchor: [0, lato == latd && longo == longd ? -100 : -20]
			})}).bindPopup("<b>" + this.mytranslate("Remote") + "<br>" + dongleIP.loc.org + "<br>(" + dongleIP.ip + ")</b>", {autoClose: false})
		];
		let group = featureGroup(markers).addTo(leafletMap);
		if (lato == latd && longo == longd)
			leafletMap.setView([latd, longd], 12);
		else
			leafletMap.fitBounds(group.getBounds());
		markers[0].openPopup();
		markers[1].openPopup();
	}
}

async httpClientPost(timeout, u, a, b) {
	try {
		let response;
		if (timeout == 0)
			response = await this.httpClient.post(u, a, b).toPromise();
		else
			response = await Promise.race([
				new Promise( resolve => { setTimeout(resolve, timeout) } ),
				this.httpClient.post(u, a, b).toPromise()
			]);
		if (response === undefined)
			return -1;
		else
			return response;
	} catch(e) { return -2; }
}

async platform() {
	if (typeof (<any>window).electron != "undefined")
		return await (<any>window).electron.ipc.invoke("os");
	else if (this.plt.is("ios") && this.plt.is("cordova"))
		return "ios";
	else if (this.plt.is("android") && this.plt.is("cordova"))
		return "android";
	else
		return "web";
}

isPlatform(a) {
	if (a == "electron")
		return typeof (<any>window).electron != "undefined";
	else if (a == "android")
		return this.plt.is("android") && this.plt.is("cordova");
	else if (a == "ios")
		return this.plt.is("ios") && this.plt.is("cordova");
	else if (a == "web")
		return !(typeof (<any>window).electron != "undefined" || (this.plt.is("android") && this.plt.is("cordova")) || (this.plt.is("ios") && this.plt.is("cordova")));
	else
		return false;
}

}

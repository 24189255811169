import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'about',
		loadChildren: () => import('./about/about.module').then( m => m.AboutModule)
	},
	{
		path: 'account',
		loadChildren: () => import('./account/account.module').then( m => m.AccountModule)
	},
	{
		path: 'add',
		loadChildren: () => import('./add/add.module').then( m => m.AddModule)
	},
	{
		path: 'dongle',
		loadChildren: () => import('./dongle/dongle.module').then( m => m.DongleModule)
	},
	{
		path: 'help',
		loadChildren: () => import('./help/help.module').then( m => m.HelpModule)
	},
	{
		path: 'import',
		loadChildren: () => import('./import/import.module').then( m => m.ImportModule)
	},
	{
		path: 'profile',
		loadChildren: () => import('./profile/profile.module').then( m => m.ProfileModule)
	},
	{
		path: 'splash',
		loadChildren: () => import('./splash/splash.module').then( m => m.SplashModule)
	},
	{
		path: 'startup',
		loadChildren: () => import('./startup/startup.module').then( m => m.StartupModule)
	},
	{
		path: '**',
		redirectTo: 'splash',
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})

export class AppRoutingModule {

constructor() {
}

}

//Used: https://js-keygen.surge.sh/ssh-util.js

function stringToArray(s) {
  return s.split("").map(c => c.charCodeAt());
}

function base64urlToArray(s) {
  return stringToArray(base64urlDecode(s));
}

function arrayToPem(a) {
  return window.btoa(a.map(c => String.fromCharCode(c)).join(""));
}

function arrayToLen(a) {
  let result = 0;
  for (let i = 0; i < a.length; i += 1) {
    result = result * 256 + a[i];
  }
  return result;
}

function integerToOctet(n) {
  const result = [];
  for (let i = n; i > 0; i >>= 8) {
    result.push(i & 0xff);
  }
  return result.reverse();
}

function checkHighestBit(v) {
  if (v[0] >> 7 === 1) {
    // add leading zero if first bit is set
    v.unshift(0);
  }
  return v;
}

function asnEncodeLen(n) {
  let result = [];
  if (n >> 7) {
    result = integerToOctet(n);
    result.unshift(0x80 + result.length);
  } else {
    result.push(n);
  }
  return result;
}

function base64urlDecode(s) {
  const step1 = s.replace(/-/g, "+"); // 62nd char of encoding
  const step2 = step1.replace(/_/g, "/"); // 63rd char of encoding
  let step3 = step2;
  switch (step2.length % 4) { // Pad with trailing '='s
    case 0: // No pad chars in this case
      break;
    case 2: // Two pad chars
      step3 += "==";
      break;
    case 3: // One pad char
      step3 += "=";
      break;
    default:
      throw new Error("Illegal base64url string!");
  }
  return window.atob(step3); // Regular base64 decoder
}

function encodePrivateKey(jwk) {
  const order = ["n", "e", "d", "p", "q", "dp", "dq", "qi"];
  const list = order.map(prop => {
    const v = checkHighestBit(stringToArray(base64urlDecode(jwk[prop])));
    const len = asnEncodeLen(v.length);
    return [0x02].concat(len, v); // int tag is 0x02
  });
  let seq = [0x02, 0x01, 0x00]; // extra seq for SSH
  seq = seq.concat(...list);
  const len = asnEncodeLen(seq.length);
  const a = [0x30].concat(len, seq); // seq is 0x30
  return arrayToPem(a);
}

function ab2str(buf) {
	return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export async function generateKeys() {
	const tmp = await window.crypto.subtle.generateKey({
		name: "RSASSA-PKCS1-v1_5",
		modulusLength: 1024,
		publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
		hash: { name: "SHA-256" },
	}, true, ["sign", "verify"]);
	const exportPublic = await window.crypto.subtle.exportKey("spki", tmp.publicKey);
	let publicKey = window.btoa(ab2str(exportPublic));
//	publicKey = "-----BEGIN PUBLIC KEY-----\n" + publicKey + "\n-----END PUBLIC KEY-----";
	const exportPrivate = await window.crypto.subtle.exportKey("jwk", tmp.privateKey);
	let privateKey = encodePrivateKey(exportPrivate);
//	privateKey = "-----BEGIN RSA PRIVATE KEY-----\n" + this.privateKey + "\n-----END RSA PRIVATE KEY-----";
	return {publicKey: publicKey, privateKey: privateKey};
}

window.generateKeys = generateKeys;
